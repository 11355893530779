import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  penTitle: string;
  penId: string;
}

class CodepenWrapper extends React.Component<Props> {
  private static scriptLoaded = false;

  private static loadScript() {
    if (CodepenWrapper.scriptLoaded === true) {
      return;
    }
    CodepenWrapper.scriptLoaded = true;
    const script = document.createElement('script');
    script.src = 'https://static.codepen.io/assets/embed/ei.js';
    script.async = true;
    document.body.appendChild(script);
  }

  public componentDidMount() {
    CodepenWrapper.loadScript();
  }

  public render() {
    return (
      <div>
        <p
          data-height="265"
          data-theme-id="0"
          data-slug-hash={this.props.penId}
          data-default-tab="css,result"
          data-user="domysee"
          data-embed-version="2"
          data-pen-title={this.props.penTitle}
          className="codepen">
          See the Pen <a href={`https://codepen.io/domysee/pen/${this.props.penId}/`}>{this.props.penTitle}</a> by
          Dominik Weber (<a href="https://codepen.io/domysee">@domysee</a>) on <a href="https://codepen.io">CodePen</a>.
        </p>
      </div>
    );
  }
}

export default CodepenWrapper;
