/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require('prismjs/themes/prism-solarizedlight.css');

exports.onRouteUpdate = ({ location, prevLocation }) => {
  try {
    window.ga(`set`, `page`, location.pathname + location.search + location.hash);
    window.ga(`send`, `pageview`);
  } catch (e) {}
};
