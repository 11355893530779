import SizedImage from "../../../../src/content/blogposts/mdx/sizedImage";
import TeamChatSrc from "../../../../src/content/blogposts/Blogpost 28 - Lead Dev/undraw_team_chat_y27k.svg";
import StatusUpdateSrc from "../../../../src/content/blogposts/Blogpost 28 - Lead Dev/undraw_status_update_jjgk.svg";
import ScrumBoardSrc from "../../../../src/content/blogposts/Blogpost 28 - Lead Dev/undraw_scrum_board_cesn.svg";
import WorkTimeSrc from "../../../../src/content/blogposts/Blogpost 28 - Lead Dev/undraw_work_time_lhoj.svg";
import SoftwareEngineerSrc from "../../../../src/content/blogposts/Blogpost 28 - Lead Dev/undraw_software_engineer_lvl5.svg";
import GoalsSrc from "../../../../src/content/blogposts/Blogpost 28 - Lead Dev/undraw_goals_w8tw.svg";
import TaskSrc from "../../../../src/content/blogposts/Blogpost 28 - Lead Dev/undraw_task_31wc.svg";
import AdjustmentsSrc from "../../../../src/content/blogposts/Blogpost 28 - Lead Dev/undraw_adjustments_p22m.svg";
import WebDeveloperSrc from "../../../../src/content/blogposts/Blogpost 28 - Lead Dev/undraw_web_developer_p3e5.svg";
import GoodTeamSrc from "../../../../src/content/blogposts/Blogpost 28 - Lead Dev/undraw_good_team_m7uu.svg";
import * as React from 'react';
export default {
  SizedImage,
  TeamChatSrc,
  StatusUpdateSrc,
  ScrumBoardSrc,
  WorkTimeSrc,
  SoftwareEngineerSrc,
  GoalsSrc,
  TaskSrc,
  AdjustmentsSrc,
  WebDeveloperSrc,
  GoodTeamSrc,
  React
};