import React from 'react';
import styled from 'styled-components';

const StyledBookNote = styled.div`
  margin-top: 2rem;

  p {
  }

  span {
    display: block;
    text-align: right;
    font-style: italic;
    font-weight: 500;
  }
`;

interface PageProps {
  page: number;
  isReference?: boolean; // This is when it references something in the book
}

interface PageRangeProps {
  startPage: number;
  endPage: number;
  isReference?: boolean;
}

type Props = PageProps | PageRangeProps;

const isPageProps = (props: Props): props is PageProps => (props as any).page != null;
const isPageRangeProps = (props: Props): props is PageRangeProps => (props as any).startPage != null;

const BookNote: React.FC<Props> = (props) => {
  if (isPageProps(props)) {
    return (
      <StyledBookNote>
        <p>{props.children}</p>
        <span>p. {props.page}</span>
      </StyledBookNote>
    );
  }
  if (isPageRangeProps(props)) {
    return (
      <StyledBookNote>
        <p>{props.children}</p>
        <span>
          p. {props.startPage} - {props.endPage}
        </span>
      </StyledBookNote>
    );
  }
};

export default BookNote;
