import SizedImage from "../../../../src/content/blogposts/mdx/sizedImage";
import ImageComparison from "../../../../src/content/blogposts/Blogpost 22 - An Indie Hacker's Story - Two Years of Developing a No-Fluff Dream Journal/imageComparison";
import LaunchLinks from "../../../../src/content/blogposts/Blogpost 22 - An Indie Hacker's Story - Two Years of Developing a No-Fluff Dream Journal/launchLinks";
import OldWebAppMobileSrc from "../../../../src/content/blogposts/Blogpost 22 - An Indie Hacker's Story - Two Years of Developing a No-Fluff Dream Journal/static/old-web-app-mobile.png";
import AppStoreReviewsSrc from "../../../../src/content/blogposts/Blogpost 22 - An Indie Hacker's Story - Two Years of Developing a No-Fluff Dream Journal/static/app-store-reviews.png";
import WebsiteOldSrc from "../../../../src/content/blogposts/Blogpost 22 - An Indie Hacker's Story - Two Years of Developing a No-Fluff Dream Journal/static/website-old.png";
import WebsiteNewSrc from "../../../../src/content/blogposts/Blogpost 22 - An Indie Hacker's Story - Two Years of Developing a No-Fluff Dream Journal/static/website-new.png";
import ProfileViewOldSrc from "../../../../src/content/blogposts/Blogpost 22 - An Indie Hacker's Story - Two Years of Developing a No-Fluff Dream Journal/static/profile-view-old.png";
import ProfileViewNewSrc from "../../../../src/content/blogposts/Blogpost 22 - An Indie Hacker's Story - Two Years of Developing a No-Fluff Dream Journal/static/profile-view-new-vertical.png";
import * as React from 'react';
export default {
  SizedImage,
  ImageComparison,
  LaunchLinks,
  OldWebAppMobileSrc,
  AppStoreReviewsSrc,
  WebsiteOldSrc,
  WebsiteNewSrc,
  ProfileViewOldSrc,
  ProfileViewNewSrc,
  React
};