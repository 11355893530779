import React from 'react';
import styled, { css } from 'styled-components';

const StyledSizedImage = styled.div<{ maxWidth?: string; maxHeight?: string }>`
  display: flex;
  justify-content: center;

  img {
    ${(props) =>
      props.maxWidth != null &&
      css`
        max-width: ${props.maxWidth} !important;
      `}
    ${(props) =>
      props.maxHeight != null &&
      css`
        max-height: ${props.maxHeight} !important;
      `}
  }
`;

interface Props {
  src: string;
  maxWidth?: string;
  maxHeight?: string;
}

const SizedImage: React.FC<Props> = (props) => (
  <StyledSizedImage maxWidth={props.maxWidth} maxHeight={props.maxHeight}>
    <img src={props.src} />
  </StyledSizedImage>
);

export default SizedImage;
