import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;

  td {
    vertical-align: top;
  }
`;

interface Props {
  leftImageSrc: string;
  rightImageSrc: string;
}

const ImageComparison: React.FC<Props> = (props) => (
  <StyledTable>
    <thead>
      <tr>
        <th>Old</th>
        <th>New</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <img src={props.leftImageSrc} />
        </td>
        <td>
          <img src={props.rightImageSrc} />
        </td>
      </tr>
    </tbody>
  </StyledTable>
);

export default ImageComparison;
