import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  height: 50px;

  img {
    height: 50px;
  }
`;

interface Props {
  to: string;
}

const LaunchLinks: React.FC<Props> = (props) => (
  <div>
    <StyledLink href={props.to} target="_blank">
      {props.children}
    </StyledLink>
  </div>
);

export default LaunchLinks;
